/* TabLink
 *
 * Wrapper around gatsby Link
 * Just adds a # in the url to scroll to content the user is interested in.
 */

import React from "react"
import { Link } from "gatsby"

export default function TabLink(props) {
  return (
    <Link
      className={props.className}
      to={`${props.to}#TabContent`}
      data-qa={props["data-qa"] || "TabLink"}
    >
      {props.children}
    </Link>
  )
}
