/* DynamicImage
 * Props:
 *   src: image filename (no dirs)
 *   alt: alt text (defaults to filename de-slugified)
 *   shouldShowBackground: show a white background box,
 *     only visible disproportionate (tall or long) images.
 *     default=true
 *   containerStyle: size spec {width: "css width", height: "css height"}
 *
 * Example:
 *   <DynamicImage src="representative_image.jpg" alt="foo" />
 *   <DynamicImage
 *     containerStyle={{width: "300px", height: "300px"}}
 *     src="representative_image.jpg"
 *     alt="foo"
 *   />
 *
 */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { filenameToText } from "../util/sluggo"
import useIsClient from "../util/rehydration"

/*******************************************************************************
 * DynamicImage
 ******************************************************************************/
export default function DynamicImage(props) {
  // For addressing rehydration issue
  // https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/
  const { isClient, key } = useIsClient()

  let containerStyle = {
    width: "100%",
    height: "100%",
  }

  if (props.shouldShowBackground) {
    containerStyle = {
      ...containerStyle,
      backgroundColor: "var(--imageBackgroundColor)",
      boxShadow: "inset 0 0 2px 48px var(--backgroundColor)",
    }
  }
  containerStyle = {
    ...containerStyle,
    ...props.containerStyle,
  }

  const data = useStaticQuery(imageQuery)
  const image = getImage(data.allFile.nodes.find(
    node => node.base === props.src
  ))

  if ( !isClient ) return null
  return (
    <div
      key={key}
      style={containerStyle}
      data-qa={props["data-qa"] || "DynamicImage"}
    >
      <GatsbyImage
        image={image}
        alt={props.alt || filenameToText(props.src)}
        objectFit={"contain"}
        style={containerStyle}
      />
    </div>
  )
}

DynamicImage.defaultProps = {
  shouldShowBackground: true,
  containerStyle: {}
}

const imageQuery = graphql`
  query {
    allFile(filter: {extension: {in: ["png", "jpg", "jpeg"]}}) {
    nodes {
      base
      childImageSharp {
        gatsbyImageData
      }
    }
  }
}
`
