/* User defined data
 *
 * Input user data here.
 * Ensure each item is defined, at least as an empty string.
 */
import React from "react"
import styled, { keyframes } from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Breakpoints } from "./src/util/breakpoints"
import useSiteMetadata from "./src/hooks/use-site-metadata"
import useUserPreferences from "./src/hooks/use-user-preferences"

import Carousel from "./src/components/carousel"
import TabLink from "./src/components/tab-link"
import SlideShow from "./src/components/slideshow"
import DynamicImage from "./src/components/dynamic-image"
import GoogleMap from "./src/components/google-map"

import TabList from "./src/components/tab-list"
//import Title from "./src/components/title"

/**********************************************************************
 * Input client website customization below
 **********************************************************************/

const Rows = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 100%;
`

const TwoColumnFixed = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: ${props => props.leftWidth} auto;
  margin-bottom: 24px;
`

const TwoColumn = styled(TwoColumnFixed)`
  grid-template-columns: auto;
  @media ${Breakpoints.smallOrLarger} {
    grid-template-columns: ${props => props.leftWidth} auto;
`

const ThreeColumn = styled(TwoColumnFixed)`
  grid-template-columns: auto;
  align-items: start;
  @media ${Breakpoints.smallOrLarger} {
    grid-template-columns: repeat(3, 33%);
`

const FloatingImageContainer = styled.div`
  width: min(${props => props.imgWidth || "30%"}, calc(40vh));
  float: right;
  padding-left: 8px;
  margin-bottom: 12px;
`

const PictureColumn = styled.div`
  display: inline-grid;
  align-items: center;
  grid-template-columns: 100%
  grid-column-gap: 24px;
  grid-row-gap: 24px;
`

const Bullets = styled.ul`
  li {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`

const ShortLine = styled.p`
  margin-top: 4px;
  margin-bottom: 4px;
`

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-column-gap: 10px;
  grid-row-gap: 8px;
  align-items: center;
`

// Define what goes into the footer.
const footer = (<>
  <p>
    &copy; 2021&nbsp;
    <a href="https://georgetowncarpet.com/" target="_blank" rel="noreferrer">
      Georgetown Carpet
    </a>. All rights reserved.
    Fontawesome icons are licensed under&nbsp;
    <a href="https://fontawesome.com/license" target="_blank" rel="noreferrer">
      CC-BY 4.0</a>. 
  </p>
</>)

/* Tab info and settings
 * Any tabs you want.
 *
 * For special tabs and components, you'll need to adjust imports in this file
 *
 * Use DynamicImage or Thumbail for images over img
 */

const productsTab = {
  slug: "products",
  name: "Products",
  content: (<>
    <h2>Products</h2>
    <p style={{textAlign: "center"}}><i>
      We offer the full range of flooring products
      from the world's best manufacturers.
    </i></p>

    <h2>Carpets and Area Rugs</h2>
    <div style={{width: "min(100%, 100vh)", margin: "auto"}}>
      <Carousel images={[
        "striped_stairway_carpet.jpg",
        "closeup_stairway_carpet.jpg",
        "riveted_stairway_carpet.jpg",
        "zigzag_stairway_carpet.jpg",
        "gradient_stairway_carpet.jpg",
        "cobblestone_stairway_carpet.jpg",
        "mushroom_stairway_carpet.jpg",
      ]} />
    </div>
    <p>
      We carry a large selection of high-quality wall-to-wall carpets,
      area rugs, and runners,
      including imported handmade and machine made rugs.
      These floorings come in a wide array of natural and synthetic fibers,
      including wool, sisal, jute, seagrass, viscose, and nylon.
      Each of these materials is unique and allows for
      elegant designs, sophisticated colors, and spectacular textures.
      Come to one of our showrooms and to see the full range of
      the products we carry and have
      our expert staff answer any questions that you may have.
    </p>

    <h2>Hard Flooring</h2>
    <div style={{width: "min(100%, 100vh)", margin: "auto"}}>
      <Carousel images={[
        "portal_starway_carpet.png",
      ]} />
    </div>
      <p>
      Hard Floorings are elegant, long-lasting, and easy to clean.
      We carry a full range of hard floorings to meet all of your needs.
    </p>
    <Bullets>
      <li>
        <i>Hardwood and Engineered Wood</i>:
        Hardwood has always been one of the most elegant flooring solutions;
        it gives an unparalleled elegance to any room.
        We carry a range of different wood types,
        including oak, hickory, cherry, and maple as well as exotic woods.
        Our wood floorings come in planks ranging from 2½ in. to 7 in.
        Our main two manufacturers for hardwood floorings are Mohawk and Max Windsor.
      </li>
      <li>
        <i>Bamboo</i>:
        An eco-friendly alternative to hardwood,
        bamboo flooring has a distinctive visual texture and is as durable as any hardwood.
        It is a green alternative to hardwoods,
        regrowing up to 15 times faster than some hardwoods.
        We carry DuroDesign's and Shaw's bamboo flooring.
      </li>
      <li>
        <i>Tiles</i>:
        Tile flooring gives a clean, crisp look to a home.
        We carry excellent quality tiles made from porcelain, ceramic, and natural stone.
        We also carry Duraceramic tiles, which can be installed either with or without grout.
      </li>
      <li>
        <i>Laminate</i>:
        Laminate flooring offers a good look at an affordable price.
        It is the ultimate ease in maintenance, being easy to install and clean.
      </li>
    </Bullets>

    <h2>Green Flooring</h2>
    <div style={{width: "min(100%, 100vh)", margin: "auto"}}>
      <Carousel images={[
        "woven_stairway_carpet.jpg",
        "small_striped_stairway_carpet.jpg",
        "plaid_stairway_carpet.jpg",
      ]} />
    </div>
    <p>
      As people become more conscious of
      the environment and the effects we have on it,
      flooring companies are doing what they can to
      make their products more eco-friendly while
      continuing to manufacture first-rate, durable products.
      We carry some of the newest kinds of green flooring, including:
    </p>
    <Bullets>
      <li>
        <i>Mohawk's SmartStrand Silk carpet</i>:
        A carpet made of Dupont's renewably sourced polymer.
      </li>
      <li>
        <i>Bamboo</i>:
        Bamboo is a gorgeous alternative to traditional hardwood floors.
        Bamboo regrows to harvestable size within 3-7 years,
        whereas traditional hardwoods can take 30-50 years.
        While hardwoods regrow there is more runoff and they produce less oxygen.
        Bamboo is showing itself to be both beautiful and environmentally friendly.
      </li>
      <li>
        <i>New production processes</i>:
        Many of the manufacturers that we carry have been
        looking into ways to make production more eco-friendly by
        using more efficient and effective production methods.
        For example, Design Materials often works with
        recycled materials to preserve raw materials.
        Helios uses weaving techniques with natural wool that are
        designed to minimize material waste and conserve energy.
      </li>
      <li>
        <i>Natural materials</i>:
        Many of our flooring products are
        made from natural materials such as wool, sisal, jute, and seagrass.
        These natural materials are less petroleum intensive in production than
        synthetic materials and naturally biodegradable.
      </li>
    </Bullets>
  </>),
}

const servicesTab = {
  slug: "services",
  name: "Services",
  content: (<>
    <h2>Services</h2>
    <p><i>
      Georgetown Carpet provides a full range of services
      from the moment you walk in one of our showrooms
      to custom rugs to installation.
    </i></p>

    <Rows>
      <div>
        <h3>Purchasing and Installation</h3>
        <FloatingImageContainer>
          <DynamicImage src="armchair_tea_with_grandma.jpg" />
        </FloatingImageContainer>
        <Bullets>
          <li>
            Have one of our professional staff,
            all of who have decades of experience,
            explain our products and answer any questions that you have.
          </li>
          <li>
            After you choose your new flooring,
            we will send our professional estimator to
            your home to measure your rooms.
            The estimator will tell you how much carpet or hard flooring is necessary,
            decide where to place seams,
            and minimize material waste.
          </li>
          <li>
            Then, a date will be set for the installer to come.
            If needed, he and his staff can also move your furniture.
          </li>
          <li>
            Once your new flooring is installed,
            the installer will handle the cleanup,
            making sure to collect all the old carpet and properly dispose of it.
          </li>
        </Bullets>
      </div>

      <div>
        <h3>Wood Refinishing</h3>
        <FloatingImageContainer>
          <DynamicImage src="suburban_fireplace.jpg" />
        </FloatingImageContainer>
        <p>
          In addition to installing products,
          our expert staff can also sand and refinish old hardwood,
          returning it to its original beauty.
        </p>
        <h3 style={{marginTop: "36px"}}>Custom Rugs</h3>
        <p>
          Customers can create their own rugs and runners.
          We custom cut, bind, and add borders to
          create unique area rugs and runners that are
          designed to meet your needs and match your taste.
        </p>
      </div>

    </Rows>

    <p><TabLink to="/contact">
      Contact any of our three locations here.
    </TabLink></p>
  </>),
}

const brandsTab = {
  slug: "brands",
  name: "Brands",
  content: (<>
    <h2>Brands</h2>
    <p style={{marginBottom: "24px"}}><i>
      The most sought-after brands in the business can be found at Georgetown.
      These include:
    </i></p>
    <TwoColumn leftWidth={"15%"}>
      <a href="http://www.couristan.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="couristan.png" shouldShowBackground={false} />
      </a>
      <p>
        A well-respected name in the flooring business,
        Couristan has been a pioneer in its field since
        its founding over 80 years ago.
        It Imports handmade rugs from all over the world,
        each crafted with exceptional elegance and precision,
        making each piece a work of art.
        It also has a collection of imported,
        power-loomed area rugs that are made from
        natural fibers, synthetic fibers, and blends.
        See their <a
          href="http://www.couristan.com/" target="_blank" rel="noreferrer"
          >website</a> for
        the full listing of their products.
      </p>

      <a href="https://www.dmifloors.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="design_materials.png" shouldShowBackground={false} />
      </a>
      <p>
        Design Materials Inc. is a lead manufacturer of wall and flooring tiles.
        It uses a range of materials including porcelain, ceramic, glass, and stone.
        It offers a large selection of recycled materials and
        emphasizes making the production process itself as energy-saving and waste-minimizing as possible. 
        See their <a
          href="https://www.dmifloors.com/" target="_blank" rel="noreferrer"
          >website</a> for
        a full listing of their products and
        further information about their efforts to go green. 
      </p>

      <a href="https://www.dixie-home.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="dixie_home.png" shouldShowBackground={false} />
      </a>
      <p>
        Dixie Home has seen rapid growth and increasing demand for
        its products since its start in 2003,
        showing that despite its youth it is producing quality carpets and
        rugs that please and satisfy customers.
        See their <a
          href="https://www.dixie-home.com/" target="_blank" rel="noreferrer"
          >website</a> for
        the full listing of their products.
      </p>

      <a href="https://www.fabrica.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="fabrica.png" shouldShowBackground={false} />
      </a>
      <p>
        Fabrica is an international carpet and rug manufacturer whose
        products are designed for the high-end residential market.
        The American Flooring Association recognized it for
        its high-quality products and top retailers named it
        the premier mill in the United States.
        Its product line includes broadloom carpets, custom area rugs and hand-tufted rugs.
        Go to their <a
          href="https://www.fabrica.com/" target="_blank" rel="noreferrer"
          >website</a> for
        the full listing of their products.
      </p>

      <a href="https://fibreworks.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="fibreworks.png" shouldShowBackground={false} />
      </a>
      <p>
        Fibreworks expertly crafts a unique collection of natural fibers to
        make a one-of-a-kind collection of broadloom carpet and custom bordered area rugs.
        It is best known for having a broad range of natural fibers and materials to choose from
        including sisal, wool, jute, coir, and leather.
        Explore their <a
          href="https://fibreworks.com/" target="_blank" rel="noreferrer"
          >webpage</a> for
        a full list of products and further information about the materials it uses.
      </p>

      <a href="http://www.glen-eden.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="glen_eden.png" shouldShowBackground={false} />
      </a>
      <p>
        Glen Eden is notable producer of exceptional quality wool carpets.
        It is committed to an eco-friendly product and takes satisfaction in that
        its carpets meet the requirements of the Carpet and Rug Institute's Green Label test protocol.
        More information about its collections can be found on their <a
          href="http://www.glen-eden.com/" target="_blank" rel="noreferrer"
          >website</a>.
      </p>

      <a href="https://www.kaleen.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="kaleen.png" shouldShowBackground={false} />
      </a>
      <p>
        Kaleen offers a stylish selection of Broadloom and PureLife rugs.
        See their <a
          href="https://www.kaleen.com/" target="_blank" rel="noreferrer"
          >website</a> for
        more information.
      </p>

      <a href="https://www.karastan.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="karastan.png" shouldShowBackground={false} />
      </a>
      <p>
        Since 1928, Karastan has been taking pride in,
        “weaving the finest handmade rugs a machine ever made.”
        It uses machine looms to produce stylish and dignified rugs with
        both innovative and traditional designs.
        Visit their <a
          href="https://www.karastan.com/" target="_blank" rel="noreferrer"
          >website</a> for
        a full product line.
      </p>

      <a href="https://www.maslandcarpets.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="masland.png" shouldShowBackground={false} />
      </a>
      <p>
        Masland has been creating beautifully designed and colored rugs and carpets for over 140 years,
        making it one of the oldest most trusted names in flooring.
        Using high-quality wool and branded nylon and
        adhering to high standards in its production and manufacturing,
        Masland floorings are both artistic and enduring.
        See their <a
          href="https://www.maslandcarpets.com/" target="_blank" rel="noreferrer"
          >website</a> for
        a full listing of their products.
      </p>

      <a href="https://www.mohawkflooring.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="mohawk.png" shouldShowBackground={false} />
      </a>
      <p>
        Mohawk offers a full range of flooring products including
        carpet, area rugs, tiles, hardwood, and laminate.
        Mohawk is one of leaders in green flooring and
        is well known for its SmartStrand Silk carpet that
        is made from renewably sourced polymer.
        Its hardwoods come in a full range of colors from
        light, amber-colored woods to darker hues,
        all of which bring a natural elegance to one's home.
        Mohawk is always improving on its production processes to become more environmentally friendly.
        Explore their <a
          href="https://www.mohawkflooring.com/" target="_blank" rel="noreferrer"
          >website</a> for
        a full listing of their products and further information about their efforts to go green.
      </p>

      <a href="https://www.nourison.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="nourison.png" shouldShowBackground={false} />
      </a>
      <p>
        One of the most well-known and renowned names for carpets and area rugs,
        Nourison's products depict exquisite taste and superb construction.
        Their <a
          href="https://www.nourison.com/" target="_blank" rel="noreferrer"
          >website</a> includes
        the full collection of their products.
      </p>

      <a href="https://prestigemills.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="prestige_mills.png" shouldShowBackground={false} />
      </a>
      <p>
        Prestige Mills is a major producer of carpet and rugs.
        It uses both natural and synthetic materials, including
        wool, sisal, and nylons, to make fashionable and gracious floorings.
        It has several divisions, including Concepts International and Prestige Mills.
        On their <a
          href="https://prestigemills.com/" target="_blank" rel="noreferrer"
          >website</a> more
        information is available.
      </p>

      <a href="https://www.provenzafloors.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="provenza.png" shouldShowBackground={false} />
      </a>
      <p>
        Provenza specializes in innovative hardwood and waterproof luxury vinyl plank products.
        With over 40 years of experience in the design of innovative and beautiful floor and wall covering options,
        Provenza offers hardwood collections that are unique and handcrafted.
        See their <a
          href="https://www.provenzafloors.com/" target="_blank" rel="noreferrer"
          >website</a> for
        more information.
      </p>

      <a href="https://shawfloors.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="shaw.png" shouldShowBackground={false} />
      </a>
      <p>
        Shaw is a lead supplier of carpet and area rugs.
        These are well-designed, elegant solutions to turn any house into a comfortable home.
        See their <a
          href="https://shawfloors.com/" target="_blank" rel="noreferrer"
          >website</a> for
        a full product listing.
      </p>

      <a href="https://www.stantoncarpet.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="stanton.png" shouldShowBackground={false} />
      </a>
      <p>
        Stanton is a lead manufacturer and importer of high quality carpets and and custom rugs.
        Its collection of carpets, rugs, runners, and borders
        come in a variety of designs and colors,
        ensuring that there is a match for your home.
        Stanton has six main divisions,
        each known for its branded product line:
        Stanton Carpet, Antrim, Rosecore, Crescent, Hybernia and Caravan.
        Explore their <a
          href="https://www.stantoncarpet.com/" target="_blank" rel="noreferrer"
          >website</a> for
        more information about their products.
      </p>

      <a href="https://www.uniquecarpetsltd.com/" target="_blank" rel="noreferrer">
        <DynamicImage src="unique_carpets.png" shouldShowBackground={false} />
      </a>
      <p>
        Unique Carpets, Ltd. offers a range of carpet and area rugs
        including tufted and woven wool carpets and rugs, sisals, nylons, and handcrafted area rugs.
        It is also well known for its Shagtastic Collection,
        which is a first rate series of shag rugs and broadloom.
        See their <a
          href="https://www.uniquecarpetsltd.com/" target="_blank" rel="noreferrer"
          >website</a> for
        more information.
      </p>

    </TwoColumn>
  </>),
}

const contactTab = {
  slug: "contact",
  name: "Contact",
  content: (<>
    <h2>Contact</h2>
    <p><i>
      Please contact us by phone or email or come visit one of our store locations:
    </i></p>
    <ThreeColumn>
    <div>
      <h3>Washington, DC Branch</h3>
      <ShortLine style={{marginTop: "20px"}}>
        1815 B Wisconsin Ave. NW
      </ShortLine>
      <ShortLine>
        Washington, DC 20007
      </ShortLine>
      <ContactGrid style={{marginTop: "16px"}}>
        <i style={{color: "#604030"}} class="fas fa-phone-square fa-2x"></i>
        <a href="tel:2023422262" target="_blank" rel="noreferrer">
          (202) 342-2262</a>
        <i style={{color: "#604030"}} class="fas fa-envelope-square fa-2x"></i>
        <a style={{overflowWrap: "anywhere"}}
          href="mailto:info@georgetowncarpet.com"
        >info@georgetowncarpet.com</a>
      </ContactGrid>
      <h4 style={{marginBottom: "10px"}}>Hours</h4>
      <ShortLine>
        Mon &ndash; Sat: 10am &ndash; 7pm
      </ShortLine>
      <ShortLine>
        Sun: 12pm &ndash; 5pm
      </ShortLine>
      <GoogleMap
        margin="16px auto auto 0"
        width="90%"
        height=""
        aspectRatio="1 / 1"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.384077588509!2d-77.06938318419805!3d38.91519797956785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b630b18ac169%3A0x2889ecd6e56034d7!2s1815%20Wisconsin%20Ave%20NW%2C%20Washington%2C%20DC%2020007!5e0!3m2!1sen!2sus!4v1641935400489!5m2!1sen!2sus" />
    </div>

    <div>
      <h3>Fairfax, VA Branch</h3>
      <ShortLine style={{marginTop: "20px"}}>
        10708 B Fairfax Blvd.
      </ShortLine>
      <ShortLine>
        Fairfax, VA 22030
      </ShortLine>
      <ContactGrid style={{marginTop: "16px"}}>
        <i style={{color: "#604030"}} class="fas fa-phone-square fa-2x"></i>
        <a href="tel:7032732500" target="_blank" rel="noreferrer">
          (703) 273-2500</a>
        <i style={{color: "#604030"}} class="fas fa-envelope-square fa-2x"></i>
        <a style={{overflowWrap: "anywhere"}}
          href="mailto:info@georgetowncarpet.com"
        >info@georgetowncarpet.com</a>
      </ContactGrid>
      <h4 style={{marginBottom: "10px"}}>Hours</h4>
      <ShortLine>
        Mon &ndash; Sat: 10am &ndash; 7pm
      </ShortLine>
      <ShortLine>
        Sun: 12pm &ndash; 5pm
      </ShortLine>
      <GoogleMap
        margin="16px auto auto 0"
        width="90%"
        height=""
        aspectRatio="1 / 1"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3106.9511157452416!2d-77.31697428419943!3d38.85647597957688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b64ec18c605bfb%3A0x157f3ba5f50bcc4f!2sShops%20at%20Fairfax%2C%2010708%20Fairfax%20Blvd%2C%20Fairfax%2C%20VA%2022030!5e0!3m2!1sen!2sus!4v1641936809312!5m2!1sen!2sus" />
    </div>

    <div>
      <h3>Bethesda, MD Branch</h3>
      <ShortLine style={{marginTop: "20px"}}>
        7828 Wisconsin Ave.
      </ShortLine>
      <ShortLine>
        Bethesda, MD 20814
      </ShortLine>
      <ContactGrid style={{marginTop: "16px"}}>
        <i style={{color: "#604030"}} class="fas fa-phone-square fa-2x"></i>
        <a href="tel:3016540202" target="_blank" rel="noreferrer">
          (301) 654-0202</a>
        <i style={{color: "#604030"}} class="fas fa-envelope-square fa-2x"></i>
        <a style={{overflowWrap: "anywhere"}}
          href="mailto:info@georgetowncarpet.com"
        >info@georgetowncarpet.com</a>
      </ContactGrid>
      <h4 style={{marginBottom: "10px"}}>Hours</h4>
      <ShortLine>
        Mon &ndash; Sat: 10am &ndash; 7pm
      </ShortLine>
      <ShortLine>
        Sun: 12pm &ndash; 5pm
      </ShortLine>
      <GoogleMap
        margin="16px auto auto 0"
        width="90%"
        height=""
        aspectRatio="1 / 1"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3101.1490512608098!2d-77.09766058419629!3d38.989094479556606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c964164f9443%3A0xdaa839058e7c25ee!2s7828%20Wisconsin%20Ave%2C%20Bethesda%2C%20MD%2020814!5e0!3m2!1sen!2sus!4v1641937020826!5m2!1sen!2sus" />
    </div>

    </ThreeColumn>
  </>),
}

const tabs = [
  productsTab,
  servicesTab,
  brandsTab,
  contactTab,
]

/*******************************************************************************
 *
 * Business Splash
 *
 * Defined here to handle a lot of customization.
 * Consider relocating to components once we have themes.
 *
 ******************************************************************************/

const Separator = styled.hr`
  border-top: 1px solid lightgray;
`

// Need to move the id marker up so navigation is visible
// Use only if bottom tab list present
const TabContentPlaceholder = styled.div`
  position: relative;
  top: -3em;
`
const StyledBusinessIdentifiers = styled.div`
  width: 100%;
  display: inline-grid;
  box-sizing: border-box;
  grid-template-columns: ${props => props.shouldUseIconLogo ?
    'min-content auto' :
    'auto'
  };
  column-gap: 15px;
  justify-content: center;
  grid-template-areas: ${props => props.shouldUseIconLogo ?
    '"icon title" "description description"' :
    '"title" "description"'
  };

  @media ${Breakpoints.smallOrLarger} {
    padding: 15px;
    padding-top: 0;
    row-gap: 7px;
    grid-template-columns: ${props => props.shouldUseIconLogo ?
      'auto min-content' :
      'auto'
    };
    grid-template-areas: ${props => props.shouldUseIconLogo ?
      '"icon title" "icon description"' :
      '"title" "description"'
    };
  }
`

const TitleContainer = styled.div`
  min-width: 60vw;
  grid-area: title;
  margin-bottom: -20px;
  @media ${Breakpoints.smallOrLarger} {
    margin-bottom: -6px;
  }
`

//const IconContainer = styled.div`
//  grid-area: icon;
//  height: 70px;
//  width: 70px;
//
//  @media ${Breakpoints.smallOrLarger} {
//    height: 120px;
//    width: 120px;
//    padding-right: 15px;
//    margin-bottom: 8px;
//  }
//`

const SlideshowContainer = styled.div`
  background-color: var(--backgroundColor);
  @media ${Breakpoints.smallOrLarger} {
    margin: auto;
    max-width: 90%;
  }
  @media ${Breakpoints.largeOrLarger} {
    max-width: 80%;
  }
`

const slideLeft = keyframes`
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
`

const DescriptionContainer = styled.p`
  grid-area: description;
  font-style: italic;
  font-size: large;
  text-align: center;
  animation: ${slideLeft} 1s ease-out;

  @media ${Breakpoints.smallOrLarger} {
    margin: 0;
    vertical-align: middle;
    font-size: xx-large;
  }
`

function BusinessIdentifiers(props) {
  const siteMetadata = useSiteMetadata()
  return (
    <StyledBusinessIdentifiers
      shouldUseIconLogo={false}
      data-qa={props["data-qa"] || "BusinessIdentifiers"}
    >

      {/*
      <IconContainer data-qa={"BusinessIcon"}>
        <DynamicImage
          src="icon.png"
          alt="Business icon"
          data-qa="BusinessIcon"
          shouldShowBackground={false}
        />
      </IconContainer>
      */}

      <TitleContainer>
        <h1>
          <DynamicImage
            src="banner_logo.png"
            alt={siteMetadata.title}
            containerStyle={{width: "min(100%, 80vh)", marginLeft: "auto", marginRight: "auto"}}
            data-qa="BusinessBanner"
            shouldShowBackground={false}
          />
        </h1>
      {/*
        <Title
          text={siteMetadata.title}
          data-qa={"BusinessTitle"}
        />
      */}
      </TitleContainer>

      <DescriptionContainer data-qa={"BusinessDescription"}>
        {siteMetadata.description}
      </DescriptionContainer>

    </StyledBusinessIdentifiers>

  )
}

function TabPageSplash(props) {
  const userPreferences = useUserPreferences()
  const breakpoints = useBreakpoint()

  return (<>
    <div role={"banner"}>
      {/*
      { breakpoints.smallOrLarger &&
        <TabList
          activeTab={props.activeTab}
          tabs={tabs}
        />
      }
      */}

      {/* Using old logo and stick it to the slideshow
      <BusinessIdentifiers />
      */}
      <h1 style={{
        width: "100%",
        marginBottom: "0px",
      }}>
        <DynamicImage
          src="banner.jpg"
          containerStyle={{width: "min(100%, 100vh)", marginLeft: "auto", marginRight: "auto"}}
          data-qa="BusinessBanner"
          shouldShowBackground={false}
        />
      </h1>

      <SlideshowContainer style={{width: "min(100%, 100vh)"}}>
        <SlideShow
          images={userPreferences.splashImages}
          data-qa={"SplashImages"}
        />
      </SlideshowContainer>
    </div>
      <div style={{marginBottom: "10px"}}></div>

      {/* Ensure bottom tab list is visible when switching tabs
          Only use with bottom tab list
      */}
      <TabContentPlaceholder id="TabContent" />

    <div role={"navigation"}>
      <TabList
        activeTab={props.activeTab}
        tabs={tabs}
      />
    </div>

    <Separator />
  </>)
}

export default function getUserContent() {
  return {
    footer,
    tabs,
    BusinessIdentifiers,
    TabPageSplash,
  }
}
